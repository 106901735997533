import React, { FC, useEffect } from 'react';
import {
  useForm,
  Controller,
  SubmitHandler,
  ControllerRenderProps,
  ControllerFieldState,
  UseFormStateReturn,
} from 'react-hook-form';
import { DeviceCreateUpdateBody, DeviceIconDto, DeviceModel } from '@thingslog/repositories';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { IconDropdown } from '@thingslog/ui-components';
import IconSelector from '../../components/IconSelector/IconSelector';
import { CreateOrUpdateDeviceProps } from './models/CreateOrUpdateDeviceProps';

interface DeviceMutationFormProps {
  createOrUpdateDeviceData: CreateOrUpdateDeviceProps;
  onUpdateDeviceManual?: (device: string, body: DeviceCreateUpdateBody) => void;
  onCreateDeviceManual?: (device: DeviceCreateUpdateBody) => void;
  iconsDropdownOptions: DeviceIconDto[];
  deviceModelsDropdownOptions: DeviceModel[];
  canEditDeviceModel?: boolean;
  deviceNumberPrefix?: string;
}

const DeviceMutationForm: FC<DeviceMutationFormProps> = ({
  createOrUpdateDeviceData,
  onUpdateDeviceManual,
  onCreateDeviceManual,
  iconsDropdownOptions,
  deviceModelsDropdownOptions,
  canEditDeviceModel,
  deviceNumberPrefix,
}: DeviceMutationFormProps) => {
  const { t } = useTranslation();
  const isUpdate = createOrUpdateDeviceData.type === 'UPDATE';

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    watch,
    trigger,
  } = useForm<DeviceCreateUpdateBody>({
    defaultValues: {
      number: '',
      name: '',
      swVersion: '',
      hwVersion: '',
      model: deviceModelsDropdownOptions[0],
      deviceIcon: null,
      iconId: iconsDropdownOptions[0]?.id || 0,
      description: '',
      nomenclature: null,
    },
    mode: 'all',
  });

  const watchModel = watch('model');
  const watchDescription = watch('description');
  const watchNumber = watch('number');

  // Set form values when in update mode
  useEffect(() => {
    if (createOrUpdateDeviceData.type === 'UPDATE') {
      setValue('name', createOrUpdateDeviceData.name);
      setValue('number', createOrUpdateDeviceData.deviceNumber);
      setValue('hwVersion', createOrUpdateDeviceData.hwVersion);
      setValue('swVersion', createOrUpdateDeviceData.swVersion);
      setValue('model', createOrUpdateDeviceData.model);
      setValue('deviceIcon', createOrUpdateDeviceData.deviceIcon);
      setValue('iconId', createOrUpdateDeviceData.iconId || 0);
      setValue('description', createOrUpdateDeviceData.description);
      setValue('nomenclature', createOrUpdateDeviceData.nomenclature);

      // Trigger validation after setting values
      trigger();
    }
  }, [createOrUpdateDeviceData, setValue, trigger]);

  const doesDeviceRequireNomenclature = (model: DeviceModel): boolean => {
    switch (model) {
      case DeviceModel.LPMDL_1101:
      case DeviceModel.LPMDL_1102:
      case DeviceModel.LPMDL_1103:
      case DeviceModel.LPMDL_1103_OPENLORA:
      case DeviceModel.LPMDL_1104:
      case DeviceModel.LPMDL_1105:
      case DeviceModel.LPMDL_1106:
      case DeviceModel.TLCTL_1104:
      case DeviceModel.TLCTL_1105:
      case DeviceModel.TLCTL_1106:
      case DeviceModel.TLCTL_21A4S:
        return true;
      default:
        return false;
    }
  };

  const onSubmit: SubmitHandler<DeviceCreateUpdateBody> = (data: DeviceCreateUpdateBody) => {
    if (onUpdateDeviceManual && isUpdate) {
      onUpdateDeviceManual(data.number, data);
    } else if (onCreateDeviceManual && !isUpdate) {
      onCreateDeviceManual(data);
    }
  };

  const generateDeviceNumberHelperText = (
    deviceNumberPrefix: string | undefined,
    deviceNumber: string
  ): string => {
    let helperText = t('device_number') + ': ';
    if (deviceNumberPrefix) {
      helperText = helperText + deviceNumberPrefix + '-' + deviceNumber;
      return helperText;
    }
    return '';
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col mt-5 gap-5">
      {!isUpdate && (
        <FormControl>
          <Controller
            name="number"
            control={control}
            rules={{
              required: t('device_inventory_empty_device_error') as string,
              pattern: {
                value: /^[a-zA-Z0-9]{8}$/,
                message: t('device_inventory_device_number_helper'),
              },
            }}
            render={({
              field,
            }: {
              field: ControllerRenderProps<DeviceCreateUpdateBody, 'number'>;
              fieldState: ControllerFieldState;
              formState: UseFormStateReturn<DeviceCreateUpdateBody>;
            }): React.ReactElement => (
              <>
                <TextField
                  {...field}
                  size="small"
                  label={t('device_inventory_device_number')}
                  error={!!errors.number}
                  helperText={errors.number?.message}
                  required
                />

                <p className="text-xs ml-2 m-0">
                  {watchModel === DeviceModel.MODBUS_SLAVE &&
                    generateDeviceNumberHelperText(deviceNumberPrefix, watchNumber)}
                </p>
              </>
            )}
          />
        </FormControl>
      )}

      <Controller
        name="name"
        control={control}
        rules={{ required: t('device_inventory_empty_device_name_error') as string }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceCreateUpdateBody, 'name'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceCreateUpdateBody>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            label={t('device_inventory_device_name')}
            error={!!errors.name}
            helperText={errors.name?.message}
            required
          />
        )}
      />

      {(!isUpdate || canEditDeviceModel) && (
        <FormControl fullWidth size="small">
          <InputLabel>{t('device_inventory_model')}</InputLabel>
          <Controller
            name="model"
            control={control}
            render={({
              field,
            }: {
              field: ControllerRenderProps<DeviceCreateUpdateBody, 'model'>;
              fieldState: ControllerFieldState;
              formState: UseFormStateReturn<DeviceCreateUpdateBody>;
            }): React.ReactElement => (
              <Select {...field} size="small" label={t('device_inventory_model')}>
                {Object.values(deviceModelsDropdownOptions).map((deviceModel: DeviceModel) => (
                  <MenuItem key={deviceModel} value={deviceModel}>
                    {deviceModel}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      )}

      {(!isUpdate || (isUpdate && watchNumber)) && (
        <Controller
          name="deviceIcon"
          control={control}
          render={({
            field,
          }: {
            field: ControllerRenderProps<DeviceCreateUpdateBody, 'deviceIcon'>;
            fieldState: ControllerFieldState;
            formState: UseFormStateReturn<DeviceCreateUpdateBody>;
          }): React.ReactElement => (
            <IconSelector
              iconName={field.value}
              setIconName={(deviceIcon: string | null): void => field.onChange(deviceIcon)}
              selectorLabel={t('device_icon_old_portal')}
            />
          )}
        />
      )}

      {iconsDropdownOptions?.length > 0 && (
        <Controller
          name="iconId"
          control={control}
          render={({
            field,
          }: {
            field: ControllerRenderProps<DeviceCreateUpdateBody, 'iconId'>;
            fieldState: ControllerFieldState;
            formState: UseFormStateReturn<DeviceCreateUpdateBody>;
          }): React.ReactElement => (
            <IconDropdown
              icons={iconsDropdownOptions}
              selectedIconId={field.value}
              onIconChange={field.onChange}
              label={t('device_icon_new_portal')}
            />
          )}
        />
      )}

      <Controller
        name="description"
        control={control}
        rules={{
          maxLength: {
            value: 250,
            message: t('device_inventory_description_helper'),
          },
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceCreateUpdateBody, 'description'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceCreateUpdateBody>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            multiline
            minRows={2}
            error={!!errors.description}
            helperText={errors.description?.message}
            label={t('device_inventory_description')}
          />
        )}
      />

      <Controller
        name="swVersion"
        control={control}
        rules={{
          required: t('device_inventory_empty_software_error') as string,
          pattern: {
            value: /^(\d{1,2}\.\d{1,2}\.\d{1,2})(-.+|_.+|)$/,
            message: t('device_inventory_software_version_helper') as string,
          },
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceCreateUpdateBody, 'swVersion'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceCreateUpdateBody>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            label={t('device_inventory_software_version')}
            error={!!errors.swVersion}
            helperText={errors.swVersion?.message}
            required
          />
        )}
      />

      <Controller
        name="hwVersion"
        control={control}
        rules={{
          required: t('device_inventory_empty_hardware_error') as string,
          pattern: {
            value: /^([1-9][0-9]*)(?:\.([0-9]{1,2})){2}$/,
            message: t('device_inventory_hardware_version_helper') as string,
          },
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceCreateUpdateBody, 'hwVersion'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceCreateUpdateBody>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            label={t('device_inventory_hardware_version')}
            error={!!errors.hwVersion}
            helperText={errors.hwVersion?.message}
            required
          />
        )}
      />

      <Controller
        name="nomenclature"
        control={control}
        rules={{
          required:
            !isUpdate && doesDeviceRequireNomenclature(watchModel)
              ? (t('device_inventory_empty_nomenclature_error') as string)
              : false,
        }}
        render={({
          field,
        }: {
          field: ControllerRenderProps<DeviceCreateUpdateBody, 'nomenclature'>;
          fieldState: ControllerFieldState;
          formState: UseFormStateReturn<DeviceCreateUpdateBody>;
        }): React.ReactElement => (
          <TextField
            {...field}
            size="small"
            label={t('nomenclature')}
            error={!!errors.nomenclature}
            helperText={errors.nomenclature?.message}
            required={!isUpdate && doesDeviceRequireNomenclature(watchModel)}
          />
        )}
      />

      <Button
        type="submit"
        disabled={!isValid || (watchDescription?.length ?? 0) > 250}
        variant="contained"
      >
        {isUpdate
          ? t('device_inventory_update_device_button')
          : t('device_inventory_create_device_button')}
      </Button>
    </form>
  );
};

export default DeviceMutationForm;
