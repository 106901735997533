import React, { FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Controller, UseControllerReturn, UseFormReturn, useWatch } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import {
  digitalSensorTypeOptions,
  getSensorTypeOptionsBasedOnPortTypeAndDeviceModel,
} from '../../common/SensorTypeOptions';
import InitialConfigFormFields from '../../models/InitialConfigFormFields';
import ConnectForm from '../../../../common/reactHookForm/ConnectForm';
import { DisplayUnit, MeasurementUnit } from '@thingslog/repositories';
import { hash, HelperTextInputField } from '@thingslog/ui-components';
import { FlowTimeUnits } from '../../../../model/FlowTimeUnits/FlowTimeUnits';
import SensorTypeSelector from '../../components/SensorTypeSelector';
import InitialValueDigitsInput from '../../components/InitialValueDigitsInput';

const GenericDigitalConfig: FC<GenericDigitalConfigProps> = ({
  index,
}: GenericDigitalConfigProps) => {
  const { t } = useTranslation();

  return (
    <ConnectForm<InitialConfigFormFields>>
      {({ control, register, getValues }: UseFormReturn<InitialConfigFormFields>): ReactElement => {
        const flowUnit = useWatch({
          control,
          name: `ports.${index}.sensor.flowUnit.units`,
        });
        return (
          <>
            {/* TODO add DeviceDigits here*/}
            <Tooltip arrow title={t('config_tooltip_sensor_pulseRate') ?? ''}>
              <HelperTextInputField
                {...register(`ports.${index}.sensor.pulseRate`)}
                label={t('device_config_pulse_per', {
                  x: DisplayUnit[getValues(`ports.${index}.sensor.units`)],
                })}
                size="small"
              />
            </Tooltip>
            <Controller
              control={control}
              name={`ports.${index}.sensor.@type`}
              render={({
                field,
              }: UseControllerReturn<
                InitialConfigFormFields,
                `ports.${number}.sensor.@type`
              >): ReactElement => (
                <SensorTypeSelector
                  portType={getValues(`ports.${index}.@type`)}
                  deviceModel={getValues('@type')}
                  field={field}
                />
              )}
            />
            <HelperTextInputField
              {...register(`ports.${index}.sensor.price`)}
              label={t('sensor_config_price')}
              size="small"
            />
            <HelperTextInputField
              {...register(`ports.${index}.sensor.currency`)}
              label={t('sensor_config_currency')}
              size="small"
            />
            <Tooltip arrow title={t('config_tooltip_sensor_offset') ?? ''}>
              <HelperTextInputField
                {...register(`ports.${index}.sensor.offset`)}
                label={t('sensor_config_offset')}
                size="small"
              />
            </Tooltip>
            <div className="relative col-span-3 border border-solid border-gray-300 rounded-lg p-3">
              <div className="absolute left-10 -top-2 px-2 bg-white inline-block">
                <h2 className="text-xs  text-gray-500 font-semibold m-0">
                  {t('flow_configuration_label')}
                </h2>
              </div>

              <div className="flex flex-row space-x-4">
                <Controller
                  control={control}
                  name={`ports.${index}.sensor.flowUnit.units`}
                  render={({
                    field,
                  }: UseControllerReturn<
                    InitialConfigFormFields,
                    `ports.${number}.sensor.flowUnit.units`
                  >): ReactElement => (
                    <Tooltip
                      placement="left-start"
                      arrow
                      title={t('config_tooltip_sensor_flow_units') ?? ''}
                    >
                      <FormControl className="flex-1" size="small">
                        <InputLabel id="flow-units-select">
                          {t('flow_measurement_units')}
                        </InputLabel>
                        <Select
                          {...field}
                          value={field.value}
                          label={t('flow_measurement_units')}
                          labelId="flow-units-select"
                          onChange={(event: SelectChangeEvent): void =>
                            field.onChange(event.target.value)
                          }
                          endAdornment={
                            field.value ? (
                              <IconButton onClick={(): void => field.onChange(null)}>
                                <ClearIcon />
                              </IconButton>
                            ) : null
                          }
                          style={{ textAlign: 'left' }}
                        >
                          {Object.keys(MeasurementUnit)
                            .sort()
                            .map((keyName: string, keyIndex: number) => (
                              <MenuItem value={keyName} key={keyName} id={keyName}>
                                {MeasurementUnit[keyName]}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  )}
                />

                <Controller
                  control={control}
                  name={`ports.${index}.sensor.flowUnit.timeUnits`}
                  render={({
                    field,
                  }: UseControllerReturn<
                    InitialConfigFormFields,
                    `ports.${number}.sensor.flowUnit.timeUnits`
                  >): ReactElement => (
                    <Tooltip
                      placement="left-start"
                      arrow
                      title={t('config_tooltip_sensor_flow_time_units') ?? ''}
                    >
                      <FormControl className="flex-1" size="small">
                        <InputLabel id="flow-time-units-select-label">
                          {t('flow_time_units')}
                        </InputLabel>
                        <Select
                          {...field}
                          disabled={!flowUnit}
                          value={field.value}
                          label={t('flow_time_units')}
                          labelId="flow-time-units-select-label"
                          onChange={(event: SelectChangeEvent): void =>
                            field.onChange(event.target.value)
                          }
                          endAdornment={
                            field.value ? (
                              <IconButton onClick={(): void => field.onChange(null)}>
                                <ClearIcon />
                              </IconButton>
                            ) : null
                          }
                          style={{ textAlign: 'left' }}
                        >
                          {Object.keys(FlowTimeUnits).map((keyName: string, keyIndex: number) => (
                            <MenuItem value={keyName} key={keyName} id={keyName}>
                              {FlowTimeUnits[keyName]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  )}
                />
                <Tooltip arrow title={t('config_tooltip_sensor_flow_conversion_coefficient') ?? ''}>
                  <TextField
                    className="flex-1"
                    {...register(`ports.${index}.sensor.flowConversionCoefficient`)}
                    label={t('flow_coefficient')}
                    size="small"
                  />
                </Tooltip>
              </div>
            </div>
            <Tooltip
              arrow
              placement="right-start"
              title={t('config_tooltip_wildPoint_threshold') || ''}
            >
              <HelperTextInputField
                {...register(`ports.${index}.sensor.wildPointFilter.wildPointThreshold`, {
                  setValueAs: (value: string) => (value === '' ? null : Number(value)),
                })}
                label={t('wildPoint_threshold')}
                inputProps={{
                  step: 'any',
                }}
                type="number"
                size="small"
              />
            </Tooltip>
            <InitialValueDigitsInput index={index} />
          </>
        );
      }}
    </ConnectForm>
  );
};

interface GenericDigitalConfigProps {
  index: number;
}

export default GenericDigitalConfig;
