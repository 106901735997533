import React, { FC, useEffect, useState, useCallback } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { GridAddIcon, GridRemoveIcon } from '@mui/x-data-grid';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InitialConfigFormFields from '../models/InitialConfigFormFields';

interface InitialValueDigitsInputProps {
  index: number;
}

type NumericFormatKey = 'precision' | 'scale';

export const InitialValueDigitsInput: FC<InitialValueDigitsInputProps> = ({
  index,
}: InitialValueDigitsInputProps) => {
  // #region hooks and state setup

  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<InitialConfigFormFields>();

  const { t } = useTranslation();

  const precision = useWatch({ control, name: `ports.${index}.sensor.precision` }) ?? 4;
  const scale = useWatch({ control, name: `ports.${index}.sensor.scale` }) ?? 0;
  const watchedInitialValue = useWatch({ control, name: `ports.${index}.sensor.initialValue` });

  const [initialValue, setInitialValue] = useState<string>(
    watchedInitialValue != null ? watchedInitialValue.toString() : ''
  );

  useEffect(() => {
    setInitialValue(watchedInitialValue != null ? watchedInitialValue.toString() : '');
  }, [watchedInitialValue]);

  // #endregion

  // #region helpers

  /**
   * Ensures a number stays within a minimum and maximum.
   * Used to enforce valid ranges for precision and scale.
   */
  const clamp = (value: number, min: number, max: number): number =>
    Math.min(Math.max(value, min), max);

  /**
   * Formats the initialValue based on precision and scale.
   * Whole part is padded or trimmed to (precision - scale) digits.
   * Fractional part is padded or trimmed to exactly scale digits.
   */
  const formatInitialValue = (initialValue: string, precision: number, scale: number): string => {
    if (!initialValue) return '';
    const [whole, frac = ''] = initialValue.split('.');
    const wholeLength = precision - scale;

    const trimmedWhole = whole.slice(-wholeLength);
    const paddedWhole = trimmedWhole.padStart(wholeLength, '0');
    const paddedFrac = frac.padEnd(scale, '0').slice(0, scale);

    return scale > 0 ? `${paddedWhole}.${paddedFrac}` : paddedWhole;
  };

  const updateFormValues = useCallback(
    (precision: number, scale: number, formattedInitialValue: string) => {
      setValue(`ports.${index}.sensor.precision`, precision);
      setValue(`ports.${index}.sensor.scale`, scale);
      setValue(`ports.${index}.sensor.initialValue`, parseFloat(formattedInitialValue));
      setInitialValue(formattedInitialValue);
    },
    [index, setValue]
  );

  /**
   * Recalculates the best-fitting precision and scale based on the user input.
   * This is triggered on blur.
   *
   * Rules:
   * - Precision = total number of digits (before + after the decimal), limited to 4–9.
   * - Scale = number of digits after the decimal, limited to 0–3 and must not exceed precision.
   */
  const recalculateFromInitialValue = (initialValue: string): void => {
    if (!initialValue) return;

    const [whole, frac = ''] = initialValue.split('.');
    const computedPrecision = clamp(whole.length + frac.length, 4, 9);
    const computedScale = clamp(frac.length, 0, Math.min(3, computedPrecision));

    const formatted = formatInitialValue(initialValue, computedPrecision, computedScale);
    updateFormValues(computedPrecision, computedScale, formatted);
  };

  const applyPrecisionAndScale = (precision: number, scale: number): void => {
    const clampedPrecision = clamp(precision, 4, 9);
    const clampedScale = clamp(scale, 0, 3);
    const finalScale = Math.min(clampedScale, clampedPrecision);

    const formatted = formatInitialValue(initialValue, clampedPrecision, finalScale);
    updateFormValues(clampedPrecision, finalScale, formatted);
  };

  // #endregion

  // #region input handlers

  const handleInitialValueChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const raw = e.target.value;
    if (/^\d*\.?\d*$/.test(raw)) {
      setInitialValue(raw);
    }
  };

  const handleDigitButtonClick = (key: NumericFormatKey, delta: number): void => {
    if (key === 'scale') {
      const newScale = scale + delta;
      const [whole] = initialValue.split('.');
      const newPrecision = whole.length + newScale;

      if (newScale < 0 || newScale > 3) {
        setError(`ports.${index}.sensor.scale`, {
          type: 'maxLength',
          message: t('device_digits_alert_too_many_total_fraction'),
        });
        return;
      }

      if (newPrecision > 9) {
        setError(`ports.${index}.sensor.precision`, {
          type: 'maxLength',
          message: t('device_digits_alert_too_many_total_digits'),
        });
        return;
      }

      clearErrors([`ports.${index}.sensor.precision`, `ports.${index}.sensor.scale`]);
      applyPrecisionAndScale(Math.max(4, newPrecision), newScale);
    } else {
      const newPrecision = precision + delta;

      if (newPrecision < 4 || newPrecision > 9) {
        setError(`ports.${index}.sensor.precision`, {
          type: 'maxLength',
          message: t('device_digits_alert_too_many_total_digits'),
        });
        return;
      }

      clearErrors(`ports.${index}.sensor.precision`);
      applyPrecisionAndScale(newPrecision, Math.min(scale, newPrecision));
    }
  };

  // #endregion

  return (
    <div className="flex flex-row col-span-2 gap-4">
      <TextField
        label={t('initial_value')}
        size="small"
        fullWidth
        type="text"
        value={initialValue}
        onChange={handleInitialValueChange}
        onBlur={(): void => recalculateFromInitialValue(initialValue)}
      />

      <TextField
        label={t('precision')}
        size="small"
        fullWidth
        type="number"
        value={precision}
        error={!!errors?.ports?.[index]?.sensor?.precision}
        helperText={errors?.ports?.[index]?.sensor?.precision?.message}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          applyPrecisionAndScale(Number(e.target.value), scale)
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton
                onClick={(): void => handleDigitButtonClick('precision', -1)}
                size="small"
              >
                <GridRemoveIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={(): void => handleDigitButtonClick('precision', 1)} size="small">
                <GridAddIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        label={t('scale')}
        size="small"
        fullWidth
        type="number"
        value={scale}
        error={!!errors?.ports?.[index]?.sensor?.scale}
        helperText={errors?.ports?.[index]?.sensor?.scale?.message}
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          applyPrecisionAndScale(precision, Number(e.target.value))
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={(): void => handleDigitButtonClick('scale', -1)} size="small">
                <GridRemoveIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={(): void => handleDigitButtonClick('scale', 1)} size="small">
                <GridAddIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default InitialValueDigitsInput;
